<template>
  <div
    class="pt-8 pb-8 d-flex justify-center"
    :class="{
      'pr-8 pl-8 mr-4 ml-4': $vuetify.breakpoint.smAndDown,
      'pr-16 pl-16 mr-8 ml-8': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-app-bar app>
      <div class="text-h6 font-weight-bold ml-3">Pesquisas</div>
      <v-spacer></v-spacer>
      <v-switch
        hide-details=""
        label="Pesquisas arquivadas"
        class="mr-4"
        v-model="mostrarArquivadas"
      ></v-switch>
    </v-app-bar>
    <v-container class="d-flex justify-center">
      <v-skeleton-loader
        v-if="carregandoPesquisas"
        class="mx-auto"
        width="1000"
        type="table-tbody"
      ></v-skeleton-loader>

      <v-data-table
        v-else-if="pesquisasFiltradas.length > 0"
        :headers="cabecalhos"
        :items="pesquisasFiltradas"
        :items-per-page="-1"
        class="elevation-1 tabela"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody>
            <template v-for="pesquisa in items">
              <v-hover
                v-slot="{ hover }"
                :key="pesquisa.id"
                :class="pesquisa.status"
              >
                <tr :key="pesquisa.id">
                  <td>#{{ pesquisa.id }}</td>
                  <td>
                    <span class="text-body-1 font-weight-medium mr-2"
                      >{{ pesquisa.titulo }}
                      <v-tooltip top v-if="pesquisa.descricao">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-on="on" v-bind="attrs"
                            >mdi-text-long</v-icon
                          >
                        </template>
                        {{ pesquisa.descricao }}
                      </v-tooltip>
                    </span>
                  </td>
                  <td>
                    <div class="d-flex align-center justify-end">
                      <v-chip v-if="statusCarregando == pesquisa.id" small>
                        <v-progress-circular
                          indeterminate
                          h
                          size="20"
                          width="2"
                          color="grey"
                          class="mx-5"
                        ></v-progress-circular>
                      </v-chip>
                      <v-chip
                        v-else
                        small
                        :color="corDoEstado[pesquisa.status].fundo"
                        :text-color="corDoEstado[pesquisa.status].texto"
                      >
                        {{ pesquisa.status | eventoStatus }}
                      </v-chip>
                      <div class="menu ml-2">
                        <v-menu
                          v-model="menusAtivo[pesquisa.id]"
                          class="menu"
                          offset-x
                          offset-y
                          dense
                          v-if="hover || menusAtivo[pesquisa.id]"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="show-on-hover"
                              icon
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              @click="irParaCadastroPesquisa(pesquisa.id)"
                            >
                              Editar
                            </v-list-item>
                            <v-list-item
                              v-if="
                                !['PRODUCAO', 'ARQUIVADA'].includes(
                                  pesquisa.status
                                )
                              "
                              @click="
                                alteraStatusPesquisa(pesquisa, 'PRODUCAO')
                              "
                            >
                              Publicar
                            </v-list-item>
                            <v-list-item
                              v-if="pesquisa.status === 'PRODUCAO'"
                              @click="
                                alteraStatusPesquisa(pesquisa, 'RASCUNHO')
                              "
                            >
                              Despublicar
                            </v-list-item>
                            <v-list-item
                              v-if="pesquisa.status !== 'ARQUIVADA'"
                              @click="
                                alteraStatusPesquisa(pesquisa, 'ARQUIVADA')
                              "
                            >
                              Arquivar
                            </v-list-item>
                            <v-list-item
                              v-if="pesquisa.status === 'ARQUIVADA'"
                              @click="
                                alteraStatusPesquisa(pesquisa, 'RASCUNHO')
                              "
                            >
                              Desarquivar
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
      <v-row v-else class="mt-8">
        <v-col cols="12">
          <h2 class="grey--text text-center">Nenhuma pesquisa cadastrada</h2>
        </v-col>
      </v-row>
    </v-container>
    <FloatingBtn @click="irParaCadastroPesquisa()" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  async created() {
    this.carregaPesquisas();
  },
  components: {
    FloatingBtn,
  },
  data: () => {
    return {
      menusAtivo: {},
      mostrarArquivadas: false,
      carregandoPesquisas: false,
      statusCarregando: null,
      cabecalhos: [
        { text: "", value: "id", width: "2%", sortable: false },
        { text: "Título", value: "titulo", alig: "left", sortable: false },
        { text: "", value: "status", align: "end", width: "2%" },
      ],
      corDoEstado: {
        PRODUCAO: {
          fundo: "#4CAF50",
          texto: "#FFFFFF",
        },
        RASCUNHO: {
          fundo: "#2196F3",
          texto: "#FFFFFF",
        },
        ARQUIVADA: {
          fundo: "#999999",
          texto: "#FFFFFF",
        },
      },
    };
  },
  methods: {
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapActions("pesquisas", ["getPesquisas", "salvarPesquisa"]),
    irParaCadastroPesquisa(id = "novo") {
      this.$router.push({ name: "pesquisa", params: { id: id } });
    },
    async alteraStatusPesquisa(pesquisa, status) {
      const payload = { ...pesquisa, status: status };
      this.statusCarregando = pesquisa.id;
      try {
        const resposta = await this.salvarPesquisa(payload);
        pesquisa.status = resposta.status;
        this.showSnackBar({ text: "Status da pesquisa alterado com sucesso!" });
      } catch (e) {
        console.error(e);
        this.showSnackBar({ text: "Falha ao alterar o status da pesquisa!" });
      } finally {
        this.statusCarregando = null;
      }
    },
    async carregaPesquisas() {
      try {
        this.carregandoPesquisas = true;
        await this.getPesquisas({
          empresa_id: this.empresaSelecionada.id,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.carregandoPesquisas = false;
      }
    },
  },
  computed: {
    ...mapGetters("pesquisas", ["pesquisas"]),
    ...mapGetters("pesquisas", ["pesquisas"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    pesquisasFiltradas() {
      let ordem = {
        PRODUCAO: 1,
        RASCUNHO: 2,
        ARQUIVADA: 3,
      };

      return this.pesquisas
        .filter((item) => {
          if (this.mostrarArquivadas) return true;
          else if (item.status == "ARQUIVADA") return false;
          return true;
        })
        .sort((a, b) => ordem[a.status] - ordem[b.status]);
    },
  },
  watch: {
    empresaSelecionada: {
      handler(newValue) {
        this.carregaPesquisas();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  width: 30px;
  height: 36px;
}
.tabela {
  min-width: 450px;
  width: 1000px;
}
.ARQUIVADA {
  color: lightgrey;
}
</style>
