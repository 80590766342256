<template>
  <div class="">
    <v-app-bar app>
      <div class="text-h6 font-weight-bold ml-3">
        <router-link class="black--text text-decoration-none" to="/pesquisas"
          >Pesquisas</router-link
        >
        / {{ pesquisa.titulo }}
      </div>
      <v-spacer></v-spacer>
      <v-switch
        hide-details=""
        label="Perguntas arquivadas"
        class="mr-4"
        v-model="mostrarArquivadas"
      ></v-switch>
      <v-menu bottom v-model="menu" class="menu" dense v-if="pesquisa">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-on="on"
            v-bind="attrs"
            :color="corDoEstado[pesquisa.status].fundo"
            :text-color="corDoEstado[pesquisa.status].texto"
            class="mr-4"
            >{{ pesquisa.status | eventoStatus }}
            <v-icon>mdi-menu-down</v-icon></v-chip
          >
        </template>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="status in statusPesquisa"
              :key="status"
              @click="pesquisa.status = status"
            >
              {{ status | eventoStatus }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <div>
        <v-btn
          :loading="salvandoPesquisa"
          :disabled="!valid || salvandoPesquisa"
          @click="salvar()"
          color="primary"
          >salvar</v-btn
        >
      </div>
    </v-app-bar>

    <v-form
      lazy-validation
      v-model="valid"
      ref="form"
      v-if="pesquisa"
      class="px-8"
    >
      <v-container fill-height class="mt-8 titulo">
        <v-row class="white elevation-1 rounded">
          <div background="" class="top-bar primary rounded-t"></div>
          <v-col cols="12" class="px-4">
            <v-text-field
              v-model="pesquisa.titulo"
              class="text-h5"
              label="Título"
              validate-on-blur
              required
              :rules="regrasTitulo"
              :hint="'* Apenas a variável {{primeiro_nome}} pode ser utilizada'"
              counter="128"
              maxlength="128"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="px-4">
            <v-text-field
              v-model="pesquisa.descricao"
              label="Descrição"
              dense
              required
              counter="128"
              maxlength="128"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height class="pergunta_container">
        <draggable
          v-if="pesquisa.perguntas.length > 0"
          v-model="pesquisa.perguntas"
          draggable=".item"
          group="people"
          class="form-container flex-grow-1"
          @start="startDrag($event)"
          @end="drag = false"
          v-bind="dragOptions"
        >
          <v-row
            v-for="(pergunta, index) in perguntasFiltradas"
            :key="index"
            class="white my-4 rounded pergunta item"
            :class="[
              `pergunta-${index}`,
              pergunta.selecionada ? 'elevation-4' : 'elevation-1',
              pergunta.status == 'ARQUIVADA' ? 'pergunta-arquivada' : '',
            ]"
            @click.stop="selecionaPergunta(pergunta, index)"
          >
            <v-slide-y-transition hide-on-leave>
              <div v-if="pergunta.selecionada" class="d-flex" :key="1">
                <div class="selected rounded-l"></div>
                <v-row class="pa-4">
                  <v-col
                    cols="12"
                    class="pa-0 d-flex justify-center align-center drag drag-icon"
                    ><v-icon>mdi-drag-horizontal</v-icon></v-col
                  >
                  <v-col cols="8"
                    ><v-text-field
                      v-model="pergunta.pergunta"
                      :label="'Pergunta'"
                      maxlength="128"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col cols="4">
                    <v-select
                      @change="verificaTipoEAdicionaValores(pergunta)"
                      v-model="pergunta.tipo"
                      :label="'Tipo'"
                      :items="tiposPergunta"
                      item-value="constante"
                    >
                      <template v-slot:item="{ item }">
                        <v-icon class="mr-2">{{ item.icone }}</v-icon
                        >{{ item.nome }}
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-icon class="mr-2">{{ item.icone }}</v-icon
                        >{{ item.nome }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="8" class="px-6" v-if="pergunta.selecionada">
                    <div v-if="!pergunta.opcoes">
                      <v-row class="mb-2">
                        <component
                          hide-details
                          readonly
                          disabled
                          :label="getComponentName(pergunta)['nome']"
                          v-bind:is="getComponentName(pergunta)['component']"
                        >
                        </component>
                      </v-row>
                    </div>
                    <div v-else class="d-flex flex-column">
                      <v-row
                        class=""
                        v-for="(opcao, index) in pergunta.opcoes"
                        :key="index"
                      >
                        <component
                          hide-details
                          disabled
                          v-bind:is="getComponentName(pergunta)['component']"
                        >
                        </component>
                        <v-text-field
                          :rules="[requiredRule]"
                          :placeholder="`Opção ${index + 1}`"
                          v-model="pergunta.opcoes[index]"
                          :disabled="opcao == 'Outros'"
                          maxlength="128"
                        >
                          <template v-slot:prepend>
                            <div
                              class="mt-1"
                              v-if="pergunta.tipo == 'DROPDOWN'"
                            >
                              <div>{{ index + 1 }}.</div>
                            </div>
                          </template>
                          <template v-if="index > 0" v-slot:append
                            ><v-btn
                              small
                              @click="removeOpcao(pergunta, index)"
                              icon
                            >
                              <v-icon>mdi-close</v-icon></v-btn
                            ></template
                          >
                        </v-text-field>
                      </v-row>
                      <v-row class="mt-6 mb-4" v-if="pergunta.opcoes">
                        <v-col
                          cols="12"
                          class="pl-0"
                          v-if="pergunta.tipo === 'DROPDOWN'"
                        >
                          <div class="">
                            <span @click="adiocionarOpcao(pergunta)"
                              >{{ pergunta.opcoes.length + 1 }}. Adicionar
                              opção</span
                            >
                          </div>
                        </v-col>
                        <component
                          hide-details
                          readonly
                          v-bind:is="getComponentName(pergunta)['component']"
                          @click.prevent
                          @click="adiocionarOpcao(pergunta)"
                        >
                          <template v-slot:label>
                            <div class="">
                              <span>Adicionar opção</span>
                              <template v-if="naoTemOutros(pergunta)">
                                <span class="black--text"
                                  ><strong>&nbsp;ou</strong></span
                                >
                                <span
                                  @click.prevent
                                  @click.stop
                                  @click.self="adiocionarOutros(pergunta)"
                                  class="blue--text"
                                  >&nbsp;Adicionar "outros"</span
                                >
                              </template>
                            </div>
                          </template>
                        </component>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pa-0 pb-1">
                    <v-divider></v-divider>
                    <div class="d-flex align-center justify-end px-8 py-2">
                      <v-tooltip top v-if="pergunta.status != 'ARQUIVADA'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="pergunta.status = 'ARQUIVADA'"
                            class="mr-4"
                            v-on="on"
                            v-bind="attrs"
                            icon
                          >
                            <v-icon>mdi-delete</v-icon></v-btn
                          >
                        </template>
                        Remover pergunta
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="pergunta.status = 'PRODUCAO'"
                            class="mr-4"
                            v-on="on"
                            v-bind="attrs"
                            icon
                          >
                            <v-icon>mdi-delete-restore</v-icon></v-btn
                          >
                        </template>
                        Ativar pergunta
                      </v-tooltip>
                      <v-divider vertical class="mr-4"></v-divider>
                      <v-switch
                        hide-details
                        class="mt-0 pt-0"
                        label="Obrigatório"
                        v-model="pergunta.obrigatorio"
                      ></v-switch>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-row v-else :key="2" class="pa-4">
                <v-col
                  cols="12"
                  class="pa-0 d-flex justify-center align-center drag drag-icon"
                  ><v-icon>mdi-drag-horizontal</v-icon></v-col
                >
                <v-col cols="12"
                  ><span>{{ pergunta.pergunta }}</span>
                  <span class="red--text">{{
                    pergunta.obrigatorio ? "*" : ""
                  }}</span></v-col
                >
                <v-col cols="8">
                  <div v-if="!pergunta.opcoes">
                    <component
                      hide-details
                      disabled
                      class="mt-0 pt-0"
                      :label="getComponentName(pergunta)['nome']"
                      v-bind:is="getComponentName(pergunta)['component']"
                    >
                    </component>
                  </div>
                  <v-row
                    v-else
                    class=""
                    v-for="(opcao, index) in pergunta.opcoes"
                    :key="index"
                  >
                    <v-col cols="12" class="ml-2">
                      <span v-if="pergunta.tipo == 'DROPDOWN'">
                        {{ index + 1 }}. {{ opcao }}
                      </span>
                      <component
                        hide-details
                        disabled
                        v-bind:is="getComponentName(pergunta)['component']"
                        :label="opcao || getComponentName(pergunta)['nome']"
                        class="ma-0"
                      >
                      </component>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-slide-y-transition>
          </v-row>
        </draggable>
        <v-row v-else justify="center" align="center" class="mt-8">
          <v-col cols="12">
            <h1 class="grey--text text-center">Nenhuma pergunta cadastrada</h1>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <FloatingBtn @click="adicionarPergunta()" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapGetters } from "vuex";
import { VCheckbox, VRadio, VTextField } from "vuetify/lib";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  components: {
    VCheckbox,
    VRadio,
    VTextField,
    draggable,
    FloatingBtn,
  },
  async created() {
    const id = this.$route.params?.id;
    if (id === "novo") {
      this.novaPesquisa = true;
      this.tituloPesquisa = "Pesquisa sem titulo";
      return;
    }
    if (this.pesquisas.length == 0) {
      await this.getPesquisas();
    }
    this.pesquisa = this.pesquisas.find((pesquisa) => pesquisa.id == id);
    this.pesquisa.perguntas.sort((a, b) => {
      return a.ordem > b.ordem ? 0 : -1;
    });
    this.tituloPesquisa = this.pesquisa.titulo;

    if (!this.pesquisa) {
      this.$router.push({ name: "pesquisas" });
      return;
    }
  },
  data: () => {
    return {
      requiredRule: (v) => !!v || "Campo obrigatório",
      novaPesquisa: true,
      pesquisa: null,
      tituloPesquisa: null,
      valid: true,
      drag: false,
      menu: false,
      salvandoPesquisa: false,
      mostrarArquivadas: false,
      statusPesquisa: ["ARQUIVADA", "PRODUCAO", "RASCUNHO"],
      corDoEstado: {
        PRODUCAO: {
          fundo: "#4CAF50",
          texto: "#FFFFFF",
        },
        RASCUNHO: {
          fundo: "#2196F3",
          texto: "#FFFFFF",
        },
        ARQUIVADA: {
          fundo: "#999999",
          texto: "#FFFFFF",
        },
      },
      pesquisa: {
        titulo: "Pesquisa sem título",
        descricao: "",
        status: "RASCUNHO",
        perguntas: [
          {
            pergunta: "Pergunta 1",
            tipo: "TEXTO_CURTO",
            opcoes: null,
            obrigatorio: true,
            ordem: 0,
            status: "PRODUCAO",
          },
          {
            pergunta: "Pergunta 2",
            tipo: "MULTIPLA_ESCOLHA",
            opcoes: ["Opção 1", "Opção 2"],
            obrigatorio: true,
            ordem: 1,
            status: "PRODUCAO",
          },
        ],
      },
      tiposPergunta: [
        {
          constante: "MULTIPLA_ESCOLHA",
          component: "v-radio",
          nome: "Múltipla escolha",
          icone: "mdi-radiobox-marked",
        },
        {
          constante: "CHECKBOX",
          component: "v-checkbox",
          nome: "Checkbox",
          icone: "mdi-checkbox-marked",
        },
        {
          constante: "TEXTO_CURTO",
          component: "v-text-field",
          nome: "Texto curto",
          icone: "mdi-text-short",
        },
        {
          constante: "TEXTO_LONGO",
          component: "v-text-field",
          nome: "Texto longo",
          icone: "mdi-text-long",
        },
        {
          constante: "DROPDOWN",
          component: null,
          nome: "Lista suspensa",
          icone: "mdi-arrow-down-drop-circle",
        },
      ],
      regrasTitulo: [
        (value) => !!value || "Campo obrigatório",
        (value) => {
          const regex = /{{(.*?)}}/g;
          const matches = value.match(regex);
          if (
            !matches ||
            (matches.includes("{{primeiro_nome}}") && matches.length == 1)
          )
            return true;
          return "Variável não permitida";
        },
      ],
    };
  },
  methods: {
    ...mapActions("pesquisas", ["getPesquisas", "salvarPesquisa"]),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    async salvar() {
      await this.$refs.form.validate();
      if (!this.valid) {
        let el = window.document.querySelector(".error--text");
        this.$vuetify.goTo(el);
        return;
      }
      this.pesquisa.perguntas.forEach((pergunta) => {
        delete pergunta.selecionada;
        delete pergunta.criado_em;
        delete pergunta.criado_por;
        delete pergunta.atualizado_em;
        delete pergunta.atualizado_por;
      });
      this.salvandoPesquisa = true;
      try {
        await this.salvarPesquisa(this.pesquisa);
        this.$router.push({ name: "pesquisas" });
        this.showSnackBar({
          text: `Pesquisa salva com sucesso!`,
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Falha ao salvar a pesquisa!`,
        });
      } finally {
        this.salvandoPesquisa = false;
      }
    },
    getComponentName(pergunta) {
      if (!this.tiposPergunta.find((tipo) => tipo.constante == pergunta.tipo)) {
        return;
      }
      return this.tiposPergunta.find((tipo) => tipo.constante == pergunta.tipo);
    },
    adiocionarOutros(pergunta) {
      pergunta.opcoes.push("Outros");
    },
    adiocionarOpcao(pergunta) {
      if (pergunta.opcoes.some((opcao) => opcao == "Outros")) {
        pergunta.opcoes.splice(pergunta.opcoes.length - 1, 0, "");
      } else {
        pergunta.opcoes.push("");
      }
    },
    removeOpcao(pergunta, indexOpcao) {
      pergunta.opcoes.splice(indexOpcao, 1);
    },
    naoTemOutros(pergunta) {
      return !pergunta.opcoes.some((opcao) => opcao == "Outros");
    },
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
    async adicionarPergunta() {
      this.pesquisa.perguntas.push({
        pergunta: "Pergunta sem título",
        tipo: "TEXTO_CURTO",
        opcoes: null,
        obrigatorio: true,
        status: "PRODUCAO",
      });
      await this.$nextTick();
      this.$vuetify.goTo(`.pergunta-${this.perguntasFiltradas.length - 1}`);
      const tamanhoArrayPerguntas = this.perguntasFiltradas.length;
      this.selecionaPergunta(
        this.perguntasFiltradas[tamanhoArrayPerguntas - 1],
        tamanhoArrayPerguntas - 1
      );
    },
    removerPergunta(index) {
      this.pesquisa.perguntas.splice(index, 1);
    },
    verificaTipoEAdicionaValores(pergunta) {
      const tiposComValores = ["CHECKBOX", "MULTIPLA_ESCOLHA", "DROPDOWN"];
      if (tiposComValores.includes(pergunta.tipo)) {
        pergunta.opcoes = ["Opção 1"];
      } else {
        delete pergunta.opcoes;
      }
    },
    selecionaPergunta(pergunta, index) {
      if (pergunta.selecionada) return;
      this.pesquisa.perguntas.forEach((item) => {
        if (item != pergunta) item.selecionada = false;
      });
      this.$set(this.perguntasFiltradas[index], "selecionada", true);
      this.pesquisa = JSON.parse(JSON.stringify(this.pesquisa));
      this.$vuetify.goTo(`.pergunta-${index}`);
    },
    startDrag(event) {
      this.drag = false;
    },
  },
  computed: {
    ...mapGetters("pesquisas", ["pesquisas"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        handle: ".drag-icon",
      };
    },
    perguntasFiltradas() {
      if (this.mostrarArquivadas) return this.pesquisa.perguntas;
      return this.pesquisa.perguntas.filter((pergunta) => {
        return pergunta.status != "ARQUIVADA";
      });
    },
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.$router.replace("/pesquisas");
      },
    },
    "pesquisa.perguntas": {
      handler(newValue) {
        this.pesquisa.perguntas.forEach((pesquisa, index) => {
          this.pesquisa.perguntas[index].ordem = index;
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.titulo {
  max-width: 1000px;
}
.pergunta_container {
  max-width: 1000px;
}
.pergunta {
  .drag {
    cursor: grab;
    height: 25px;
    i {
      display: none !important;
    }
  }
  &:hover {
    .drag {
      i {
        display: flex !important;
      }
    }
  }
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.top-bar {
  height: 10px;
  width: 100%;
  background: #d2dcff;
}
.selected {
  background: #d2dcff;
  width: 5px;
}
.pergunta-arquivada {
  background: rgba(128, 128, 128, 0.119) !important;
  color: rgb(171, 171, 171) !important;
}
</style>
